import React, { Component } from 'react';
import Button from './button';
import apk from './apk.apk';

export default class Demo extends Component {

    constructor() {
        super();
        this.state = {
            isUploading: false,
            uploadProgress: 0,
            progress: 0
        };
    }

    upload = () => {
        if (this.state.isUploading) return;

        fetch(apk).then((response) => {
            console.log("response=>", response)
            response.blob().then((blob) => {
                console.log("blob=>", blob)

                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "SamplePDF.pdf";
                alink.click();
            });
        });


        // this.setState({ isUploading: true });
        // const uploadInterval = setInterval(() => {
        //     if (this.state.isUploading) {
        //         this.setState((prevState) => ({
        //             uploadProgress:
        //                 prevState.uploadProgress < 100 ? prevState.uploadProgress + 2 : 100,
        //             isUploading: prevState.uploadProgress < 100,
        //         }));
        //         if (this.state.uploadProgress >= 100) {
        //             clearInterval(uploadInterval);
        //             this.installApk(); // Call function to initiate APK installation
        //         }
        //     }
        // }, 200);
    };


    installApk = () => {
   

        fetch(apk).then((response) => {
            console.log("response=>", response)
            response.blob().then((blob) => {
                console.log("blob=>", blob)

                // Creating new object of PDF file
                const fileURL =
                    window.URL.createObjectURL(blob);

                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "SamplePDF.pdf";
                alink.click();
            });
        });
    };


    handleDownload = () => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', apk, true);
        xhr.responseType = 'blob';

        xhr.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentage = (event.loaded / event.total) * 100;
                this.setState((prevState) => {
                    return { ...prevState, progress: Math.round(percentage) }
                })
            }
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                // Handle successful download
                console.log('Download complete');
            }
        };

        xhr.send();
    };

    
    render() {
        console.log(this.state.uploadProgress);
        console.log('this.state.progress =>',this.state.progress);

        return (
            <main>
                <div className="container">
                 
                <a 
    href={apk}  
    download={"Visit For Earn : Make Money"} 
    onClick={this.handleDownload}  
    style={{
        backgroundColor: "#01875f",
        padding: "5px",
        display: "block", // Make it a block element
        width: "380px",    // Set width to 100% for full width
        boxSizing: "border-box", // Include padding in the width
        textDecoration: "none",  // Remove default underline
        textAlign: "center", 
        color:"white",
        borderRadius:"5px"    // Center text
    }}>
    Install  {this.state.progress > 0 && ( <span>{this.state.progress}% </span>)}
</a>

      {/* <div>
        {this.state.progress > 0 && (
          <div>
            <span>{this.state.progress}%</span>
          </div>
        )}
      </div> */}
                </div>
            </main>
        );
    }
}
   
{/* <Button
                        label={this.state.isUploading ? 'Installing...' : 'Install'}
                        loadingLabel="Installing..."
                        title="Press to upload something"
                        isLoading={this.state.isUploading}
                        progress={this.state.uploadProgress}
                        onPress={this.handleDownload}
                    /> */}