import React from 'react';
import PropTypes from 'prop-types';



const button = props => {

  console.log(props.progress)

	return (
		<button
			className='button'
			title={props.title}
			onClick={props.onPress}
			disabled={props.disabled || props.isLoading}
		>
      {props.isLoading && 
        <>
        <div className='loadingOverlay' 
          style={{transform: `translateX(${props.progress}%)`}} 
        />
        <svg className='loadingSpinner' viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="8"/>
      </svg>
        </>}

			{props.isLoading ? props.loadingLabel : props.label}

		</button>
	);
};

button.propTypes = {
	label: PropTypes.string.isRequired,
  loadingLabel: PropTypes.string.isRequired,
	onPress: PropTypes.func.isRequired,

  isLoading: PropTypes.bool,
  progress: PropTypes.number,

	disabled: PropTypes.bool,
	title: PropTypes.string
};

export default button;
